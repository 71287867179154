<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Configuration" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Administrator email *"
                  v-model="administratorEmail"
                  :error="!!errors.app_email_admin"
                  :error-messages="errors.app_email_admin"
                  @input="delete errors.app_email_admin"
                />
                <va-input
                  label="Facebook page"
                  v-model="facebookPage"
                  :messages="['Example: https://facebook.com']"
                  :error="!!errors.app_link_facebook"
                  :error-messages="errors.app_link_facebook"
                  @input="delete errors.app_link_facebook"
                />
                <va-input
                  label="Youtube channel"
                  v-model="youtubeChannel"
                  :messages="['Example: https://youtube.com']"
                  :error="!!errors.app_link_youtube"
                  :error-messages="errors.app_link_youtube"
                  @input="delete errors.app_link_youtube"
                />
                <va-input
                  label="Made By *"
                  v-model="madeBy"
                  :error="!!errors.app_madeBy"
                  :error-messages="errors.app_madeBy"
                  @input="delete errors.app_madeBy"
                />
                <va-input
                  label="Copyright *"
                  v-model="copyright"
                  :error="!!errors.app_copyright"
                  :error-messages="errors.app_copyright"
                  @input="delete errors.app_copyright"
                />
                <va-input
                  label="Scripts for head end"
                  v-model="scriptEndHead"
                  type="textarea"
                  :error="!!errors.app_script_head_end"
                  :error-messages="errors.app_script_head_end"
                  @input="delete errors.app_script_head_end"
                />
                <va-input
                  label="Scripts for body begin"
                  v-model="scriptsBodyBegin"
                  type="textarea"
                  :error="!!errors.app_script_body_begin"
                  :error-messages="errors.app_script_body_begin"
                  @input="delete errors.app_script_body_begin"
                />
                <va-input
                  label="Scripts for body end"
                  v-model="scriptsBodyEnd"
                  type="textarea"
                  :error="!!errors.app_script_body_end"
                  :error-messages="errors.app_script_body_end"
                  @input="delete errors.app_script_body_end"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      administratorEmail: '',
      facebookPage: '',
      youtubeChannel: '',
      copyright: '',
      madeBy: '',
      scriptEndHead: '',
      scriptsBodyBegin: '',
      scriptsBodyEnd: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        app_email_admin: this.administratorEmail,
        app_link_facebook: this.facebookPage,
        app_link_youtube: this.youtubeChannel,
        app_copyright: this.copyright,
        app_madeBy: this.madeBy,
        app_script_head_end: this.scriptEndHead,
        app_script_body_begin: this.scriptsBodyBegin,
        app_script_body_end: this.scriptsBodyEnd,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/site-config`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/site-config/validate`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/site-config`)
        .then(response => {
          // console.log(response.data)
          this.administratorEmail = response.data.app_email_admin
          this.facebookPage = response.data.app_link_facebook
          this.youtubeChannel = response.data.app_link_youtube
          this.copyright = response.data.app_copyright
          this.madeBy = response.data.app_madeBy
          this.scriptEndHead = response.data.app_script_head_end
          this.scriptsBodyBegin = response.data.app_script_body_begin
          this.scriptsBodyEnd = response.data.app_script_body_end
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
